import React from "react";

import './Header.css';

import {Navbar, Nav} from 'react-bootstrap';

export default class Header extends React.Component {
  render() {
    return (
        <Navbar bg="light" fixed="top">
          <Navbar.Brand href="/">
              <div class="navbar-logo">
              </div>
          </Navbar.Brand>
          <Navbar.Collapse id="navbarScroll">
          <Nav
            className="mr-auto my-2 my-lg-0"
            style={{ height: '55px' }}
            navbarScroll
          >
              <div class="navbar-title">
                  Moff-Life
              </div>
              <div class="navbar-description">
                  オンライン健康増進サービス
              </div>
          </Nav>
          </Navbar.Collapse>
        </Navbar>
    );
  }
}