import React from 'react';
import Style from 'style-it';
// import { ZoomMtg } from '@zoomus/websdk';

import './App.css';
import Header from "./components/Header";
import Zoom from './components/Zoom';
import {Container, Row, Col, Form, Button} from 'react-bootstrap';
import appStoreIcon from './images/Download_on_the_App_Store_Badge_JP_RGB_blk_100317.svg';


function App() {

  // ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.7/lib', '/av');
  // ZoomMtg.preLoadWasm();
  // ZoomMtg.prepareWebSDK();
  // // loads language files, also passes any error messages to the ui
  // ZoomMtg.i18n.load('jp-JP');
  // ZoomMtg.i18n.reload('jp-JP');

  // // setup your signature endpoint here: https://github.com/zoom/websdk-sample-signature-node.js
  // //var signatureEndpoint = 'http://localhost:4000'
  // var signatureEndpoint = 'https://api.mofflifeweb.moff.mobi/signature/'
  // var apiKey = 'HlWGcrV-THaSCBc_usLm0A'
  // //var meetingNumber = 123456789
  // var role = 0
  // var leaveUrl = 'https://mofflifeweb.moff.mobi'
  // //var userName = 'user name'
  // var userEmail = ''
  // //var passWord = ''
  // // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/build/meetings/join#join-registered
  // // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/build/webinars/join#join-registered-webinar
  // var registrantToken = ''

  // const [meetingNumber, setMeetingNumber] = React.useState('');
  // const handleChange = e => setMeetingNumber(e.target.value);
  // const [userName, setUserName] = React.useState('');
  // const handleChangeUserName = e => setUserName(e.target.value);
  // const [passWord, setPassWord] = React.useState('');
  // const handleChangePassWord = e => setPassWord(e.target.value);

  // function getSignature(e) {
  //   e.preventDefault();

  //   fetch(signatureEndpoint, {
  //     method: 'POST',
  //     mode: 'cors',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       meetingNumber: meetingNumber,
  //       role: role
  //     })
  //   }).then(res => res.json())
  //   .then(response => {
  //     startMeeting(response.signature)
  //   }).catch(error => {
  //     console.error(error)
  //   })
  // }

  // function startMeeting(signature) {
  //   document.getElementById('zmmtg-root').style.display = 'block'

  //   ZoomMtg.init({
  //     leaveUrl: leaveUrl,
  //     success: (success) => {
  //       console.log(success)

  //       ZoomMtg.join({
  //         signature: signature,
  //         meetingNumber: meetingNumber,
  //         userName: userName,
  //         apiKey: apiKey,
  //         userEmail: userEmail,
  //         passWord: passWord,
  //         tk: registrantToken,
  //         success: (success) => {
  //           console.log(success)
  //         },
  //         error: (error) => {
  //           console.log(error)
  //         }
  //       })

  //     },
  //     error: (error) => {
  //       console.log(error)
  //     }
  //   })
  // }

  return (
    <div>
      <Style>
        {`
            html, body {
              overflow: scroll;
            }
        `}
      </Style>
      <Header />
      <Container fluid>
        <Row>
          <Col>
            <Zoom />
            {/* <h1>パソコンの方</h1>
            <Form>
            <Form.Group controlId="formMeetingID">
              <Form.Label>ミーティングID</Form.Label>
              <Form.Control size="lg" type="text" placeholder="ミーティングID" value={meetingNumber} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formPassWord">
              <Form.Label>パスコード</Form.Label>
              <Form.Control size="lg" type="text" placeholder="パスコード" value={passWord} onChange={handleChangePassWord} />
            </Form.Group>
            <Form.Group controlId="formUserName">
              <Form.Label>お名前</Form.Label>
              <Form.Control size="lg" type="text" placeholder="お名前" value={userName} onChange={handleChangeUserName} />
            </Form.Group>
            <Button size="lg" onClick={getSignature}>参加する</Button>
            </Form> */}
          </Col> 
          <Col>
            <h1>スマートフォン/タブレットの方</h1>
            <br />

            <h2>1. ZoomCloudMeetingsアプリをインストールしてください。</h2>
            <p class="font-size-24">iPhone/iPadの方</p>
            <a href='https://apps.apple.com/jp/app/zoom-cloud-meetings/id546505307'><img src={appStoreIcon} alt="App Store" width="180" /></a>
            <p class="font-size-24">Androidの方Google Play</p>
            <a href='https://play.google.com/store/apps/details?id=us.zoom.videomeetings&hl=ja&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img alt='Google Play で手に入れよう' src='https://play.google.com/intl/en_us/badges/static/images/badges/ja_badge_web_generic.png' width="240" /></a>
            <br />
            <h2>2. 参加方法に沿ってご参加ください。</h2>
            <p class="font-size-24">アプリを起動して、「参加」をタップして「ミーティングID」「パスコード」を入力してご参加ください。</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
