import React from 'react';
import Style from 'style-it';
import YouTube from 'react-youtube';

import './Chat.css';
import Header from "./components/Header";
import {Container, Row, Col, Card} from 'react-bootstrap';

const opts = {
  height: '390',
  width: '640',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
  },
};

function _onReady(event) {
  // access to player in all event handlers via event.target
  event.target.pauseVideo();
}

function Chat() {
  return (
    <div>
      <Style>
        {`
            html, body {
              overflow: scroll;
            }
        `}
      </Style>
      <Header />
      <Container fluid>
        <Row>
          <a href="https://mail.google.com/chat/u/0/#chat/space/AAAAf8nJoTM" target="_blank">
          <Col>
            <Card>
              <Card.Body>
                  <Card.Title>チャットのページを開く</Card.Title>
                  <Card.Text>Google Chat</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          </a>
        </Row>
        <Row>
          <Col>
            <h2>Googleアカウントのインストール方法</h2>
            <p>※既にお持ちの方は作成不要ですので、Googleチャットのインストールへおすすみください。</p>
            <YouTube videoId="KfyEpyKx_h4" opts={opts} onReady={_onReady} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Googleチャットのインストール方法と使い方</h2>
            <p>*お申し込みの方へ参加招待のメールをお送りしますので招待のメールを開封してください。</p>
            <p>*招待メールの「モフライフ」をクリックしてください。</p>
            <YouTube videoId="MZkz4E_HbuU" opts={opts} onReady={_onReady} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Googleカレンダー</h2>
            <p>※予約が必要な際は、都度、モフライフからメールで予約フォームをお送りします。</p>
            <YouTube videoId="CLqCWzsCp_E" opts={opts} onReady={_onReady} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Chat;
