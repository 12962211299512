import React from 'react';
import Style from 'style-it';
import {Link} from 'react-router-dom';

import Header from "./components/Header";
import "./Top.css";
import {Container, Row, Col, Card} from 'react-bootstrap';

import img_mofflive from './images/mofflive.png';
import img_chat from './images/chat.png';

function Top() {
  return (
    <div>
      <Style>
        {`
            html, body {
              overflow: scroll;
            }
        `}
      </Style>
      <Header />
      <Container fluid>
        <Row>
          <Link to="/live">
          <Col sm>
            <Card style={{ width: '445px', height: '24rem' }}>
              <Card.Img variant="mofflive" src={img_mofflive} />
              <Card.Body>
                  <Card.Title>モフライブ</Card.Title>
                  <Card.Text>運動ライブ<br/>体力測定<br/>健康相談<br/>講義</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          </Link>
          <Link to="/chat">
          <Col sm>
            <Card style={{ width: '445px', height: '24rem' }}>
              <Card.Img variant="chat" src={img_chat} />
              <Card.Body>
                  <Card.Title>チャット</Card.Title>
                  <Card.Text>Google Chat</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          </Link>
        </Row>
      </Container>
    </div>
  );
}

export default Top;
