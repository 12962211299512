import React, { useState } from 'react';
import { ZoomMtg } from '@zoomus/websdk';
import {Form, Button} from 'react-bootstrap';

const Zoom = () => {

    const [meetingNumber, setMeetingNumber] = useState('');
    const [userName, setUserName] = useState('');
    const [passWord, setPassWord] = useState('');

    const handleChange = e => setMeetingNumber(e.target.value);
    const handleChangeUserName = e => setUserName(e.target.value);
    const handleChangePassWord = e => setPassWord(e.target.value);

    ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.7/lib', '/av');
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    // loads language files, also passes any error messages to the ui
    ZoomMtg.i18n.load('jp-JP');
    ZoomMtg.i18n.reload('jp-JP');

    // setup your signature endpoint here: https://github.com/zoom/websdk-sample-signature-node.js
    //var signatureEndpoint = 'http://localhost:4000'
    var signatureEndpoint = 'https://api.mofflifeweb.moff.mobi/signature/'
    var apiKey = 'HlWGcrV-THaSCBc_usLm0A'
    //var meetingNumber = 123456789
    var role = 0
    var leaveUrl = 'https://mofflifeweb.moff.mobi'
    //var userName = 'user name'
    var userEmail = ''
    //var passWord = ''
    // pass in the registrant's token if your meeting or webinar requires registration. More info here:
    // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/build/meetings/join#join-registered
    // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/build/webinars/join#join-registered-webinar
    var registrantToken = ''

    function getSignature(e) {
        e.preventDefault();

        fetch(signatureEndpoint, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            meetingNumber: meetingNumber,
            role: role
        })
        }).then(res => res.json())
        .then(response => {
        startMeeting(response.signature)
        }).catch(error => {
        console.error(error)
        })
    }

    function startMeeting(signature) {
        document.getElementById('zmmtg-root').style.display = 'block'

        ZoomMtg.init({
        leaveUrl: leaveUrl,
        success: (success) => {
            console.log(success)

            ZoomMtg.join({
            signature: signature,
            meetingNumber: meetingNumber,
            userName: userName,
            apiKey: apiKey,
            userEmail: userEmail,
            passWord: passWord,
            tk: registrantToken,
            success: (success) => {
                console.log(success)
            },
            error: (error) => {
                console.log(error)
            }
            })

        },
        error: (error) => {
            console.log(error)
        }
        })
    }

    return (
        <div>
            <h1>パソコンの方</h1>
            <Form>
            <Form.Group controlId="formMeetingID">
            <Form.Label>ミーティングID</Form.Label>
            <Form.Control size="lg" type="text" placeholder="ミーティングID" value={meetingNumber} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="formPassWord">
            <Form.Label>パスコード</Form.Label>
            <Form.Control size="lg" type="text" placeholder="パスコード" value={passWord} onChange={handleChangePassWord} />
            </Form.Group>
            <Form.Group controlId="formUserName">
            <Form.Label>お名前</Form.Label>
            <Form.Control size="lg" type="text" placeholder="お名前" value={userName} onChange={handleChangeUserName} />
            </Form.Group>
            <Button size="lg" onClick={getSignature}>参加する</Button>
            </Form>
        </div>
    );
}

export default Zoom;
