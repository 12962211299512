/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:7252cd37-ff45-4841-a079-ea3dc3282bb2",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_M31y4aH4H",
    "aws_user_pools_web_client_id": "2cocqoug8ir99j2gui0jogq6tg",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "apiSignature",
            "endpoint": "https://2lj60mnjs0.execute-api.ap-northeast-1.amazonaws.com/mofflife",
            "region": "ap-northeast-1"
        }
    ]
};


export default awsmobile;
